@import '../../scss/variables.scss';

div.DadosPaciente {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 21px;
  & > h3 {
    margin: 8px 0;
    margin-top: 21px;
    font-weight: 800;
    font-size: 26pt;
    font-style: italic;
    color: $dark-blue;
  }
  & > div {
    width: 100%;
    min-height: 40px;
    padding: 5px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > span {
      font-style: italic;
    }
    & > span:first-child {
      color: $cyan;
    }
    & > span:last-child {
      color: $dark-blue;
    }
    & > *:first-child {
      max-width: 250px;
    }
  }
  & > div.first-line {
    border-bottom: 1px solid lightgray;
    & > span {
      font-weight: 800;
      font-size: 15pt;
    }
  }
  & > div.additional-line {
    & > span {
      font-size: 13pt;
      &:first-child {
        font-weight: 300;
      }
      &:last-child {
        font-weight: 800;
        & > span#ddd {
          font-size: 11pt;
        }
      }
    }
  }
  & > div.not-found {
    top: 21px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    h6 {
      color: red;
      margin: 0;
      font-size: 16pt;
      width: 150px;
      text-align: center;
    }
  }
}
