@import '../../scss/variables.scss';

div.PageContainer {
  animation: fadeIn 0.5s linear;
  padding: 30px 60px;
  display: flex;
  background-image: url('../../assets/imgs/fundo\ rotas.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  img#logo {
    position: absolute;
    top: 34px;
    left: 34px;
    width: 116px;
  }
  div#header {
    h3 {
      margin-top: 20px;
      color: $dark-blue;
      font-weight: 800;
      font-style: italic;
      text-align: center;
      font-size: 28px;
    }
  }
  div#content {
    flex: 1;
    width: 100%;
    padding: 60px 0;
    overflow-y: auto;
  }
  div#footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    h4,
    h6 {
      font-size: 15pt;
      font-style: italic;
      text-align: center;
      margin: 0;
    }
    h4 {
      color: $light-blue;
      font-weight: 800;
      margin-bottom: 3px;
    }
    h6 {
      color: $cyan;
      font-weight: 500;
      margin-bottom: 13px;
    }
    button#back {
      width: 178px;
      height: 53px;
    }
  }
}
