@import './assets/fonts/index.scss';

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

* {
  box-sizing: border-box;
  font-family: 'DSari', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  cursor: default;
  user-select: none;
  &.hidden {
    display: none;
  }
}

input {
  cursor: text;
  &:focus {
    outline: none;
  }
}

html,
body {
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
  max-width: 800px;
  max-height: 1280px;
  margin: auto;
}

div#root {
  width: 100%;
  height: 100%;
}

a {
  text-decoration: none;
}
