@font-face {
  font-family: 'DSari';
  font-weight: 100;
  src: url('./DSariThin.ttf') format('truetype');
}

@font-face {
  font-family: 'DSari';
  font-weight: 200;
  src: url('./DSariLight.ttf') format('truetype');
}

@font-face {
  font-family: 'DSari';
  font-weight: 300;
  src: url('./DSariBook.ttf') format('truetype');
}

@font-face {
  font-family: 'DSari';
  font-weight: 400;
  src: url('./DSari.ttf') format('truetype');
}

@font-face {
  font-family: 'DSari';
  font-weight: 500;
  src: url('./DSariMedium.ttf') format('truetype');
}

@font-face {
  font-family: 'DSari';
  font-weight: 600;
  src: url('./DSariBold.ttf') format('truetype');
}

@font-face {
  font-family: 'DSari';
  font-weight: 700;
  src: url('./DSariHeavy.ttf') format('truetype');
}

@font-face {
  font-family: 'DSari';
  font-weight: 800;
  src: url('./DSariBlack.ttf') format('truetype');
}

@font-face {
  font-family: 'DSari';
  font-weight: 900;
  src: url('./DSariUltraBlack.ttf') format('truetype');
}

@font-face {
  font-family: 'DSari';
  font-weight: 100;
  font-style: italic;
  src: url('./DSariThin-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'DSari';
  font-weight: 200;
  font-style: italic;
  src: url('./DSariLight-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'DSari';
  font-weight: 300;
  font-style: italic;
  src: url('./DSariBook-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'DSari';
  font-weight: 400;
  font-style: italic;
  src: url('./DSari-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'DSari';
  font-weight: 500;
  font-style: italic;
  src: url('./DSariMedium-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'DSari';
  font-weight: 600;
  font-style: italic;
  src: url('./DSariBold-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'DSari';
  font-weight: 700;
  font-style: italic;
  src: url('./DSariHeavy-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'DSari';
  font-weight: 800;
  font-style: italic;
  src: url('./DSariBlack-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'DSari';
  font-weight: 900;
  font-style: italic;
  src: url('./DSariUltraBlack-Italic.ttf') format('truetype');
}
