@import '../../scss/variables.scss';

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: lightgray;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: $light-blue;
}

div.Agendamentos {
  height: 100%;
  width: 100%;
  @include page-container-content;
  h3:last-of-type {
    margin-top: 0;
  }
  div.consultas-container {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    margin: 34px 0;
    width: 100%;
    align-items: center;
    overflow-y: auto;
    padding-top: 30px;
  }
  div.confirm-buttons {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    button {
      width: 175px;
      height: 60px;
    }
  }
}
