@import './scss/variables.scss';

div#root-container {
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  position: relative;
  img#rosa-diagonal {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 20;
  }
  div#app {
    width: 100%;
    height: 100%;
    border-radius: 90px 20px;
    background-color: white;
    overflow: hidden;
    position: relative;
    img#config {
      z-index: 100;
      position: absolute;
      top: 20px;
      right: 20px;
      border-radius: 22px;
      transition: background-color $default-transition;
      &:hover,
      &:focus {
        background-color: rgba(0, 157, 224, 0.25);
      }
    }
  }
}
