@import '../../../scss/variables.scss';

div.modal-warning {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 30px;
  min-width: 233px;
  min-height: 144px;
  max-width: 610px;
  max-height: 377px;
  border-radius: 50px 10px;
  & > h3,
  & > h6 {
    font-style: italic;
    text-align: center;
    margin-top: 0;
    margin-bottom: 21px;
  }
  & > h3 {
    font-weight: 800;
    color: $dark-blue;
    font-size: 21pt;
  }
  & > h6 {
    font-weight: 500;
    color: $cyan;
    font-size: 16pt;
  }
  button {
    padding: 10px 30px;
  }
}
