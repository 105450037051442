@import '../../scss/variables.scss';

div.Input {
  & > h6 {
    @include modal-small-text;
    margin: 13px 0;
  }
  & > input {
    border: 2px solid $dark-blue;
    border-radius: 23px 8px;
    padding: 8px 13px;
    text-align: center;
    font-style: italic;
    font-size: 20pt;
    font-weight: 500;
    color: $dark-blue;
    &::placeholder {
      color: #dadada;
      font-size: 15pt;
    }
  }
}
