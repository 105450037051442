@import '../../scss/variables.scss';

button.Button {
  display: flex;
  overflow: hidden;
  border-radius: 24px 6px;
  border: 0;
  background-color: $light-blue;
  padding: 10px;
  width: max-content;
  height: max-content;
  color: white;
  cursor: pointer;
  transition: background-color $default-transition;
  * {
    cursor: pointer;
  }
  &.disabled {
    background-color: gray;
    cursor: default;
    * {
      cursor: default;
    }
  }
  &:focus {
    outline: none;
  }
  &:not(.disabled):hover {
    background-color: rgb(67, 160, 221);
  }
  div.button-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      filter: brightness(1.5);
      margin-right: 16px;
    }
    div.button-text {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      h3.default {
        font-size: 12pt;
        font-weight: 800;
        font-style: italic;
        flex: 1;
        margin: 0;
        &.big {
          font-size: 21pt;
        }
        & ~ h6 {
          margin: 0;
          font-style: italic;
          font-size: 12pt;
          font-weight: 300;
        }
      }
    }
  }
}
