@import '../../../scss/variables.scss';

div.modal-conf {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 30px;
  min-width: 233px;
  min-height: 144px;
  max-width: 610px;
  border-radius: 50px 10px;
  & > label {
    width: 100%;
  }
  div.flex-container {
    margin: 15px 0;
    display: flex;
    width: 100%;
    &.btn-container {
      justify-content: space-evenly;
    }
    justify-content: space-between;
    align-items: center;
    button {
      margin-top: 0;
    }
    & > h3,
    & > h6 & > label {
      font-style: italic;
      text-align: center;
      margin-top: 0;
      margin-bottom: 21px;
    }
    & > h3 {
      font-weight: 800;
      color: $dark-blue;
      font-size: 21pt;
    }
    & > h6,
    & > label {
      margin-top: 0;
      font-weight: 500;
      color: $cyan;
      font-size: 16pt;
      margin-bottom: 5px;
    }
    & > input[type='checkbox'] {
      width: 32px;
      height: 32px;
    }
  }
  input,
  select {
    width: 450px;
    text-align: center;
    margin-bottom: 13px;
  }
  & > h3,
  & > h6 {
    font-style: italic;
    text-align: center;
    margin-top: 0;
    margin-bottom: 21px;
  }
  & > h3 {
    font-weight: 800;
    color: $dark-blue;
    font-size: 21pt;
  }
  & > h6 {
    font-weight: 500;
    color: $cyan;
    font-size: 16pt;
    margin-bottom: 5px;
  }
  button {
    margin-top: 21px;
    padding: 10px 30px;
  }
  select {
    border: 2px solid $dark-blue;
    border-radius: 23px 8px;
    padding: 8px 13px;
    text-align: center;
    font-style: italic;
    font-size: 16pt;
    font-weight: 500;
    color: $dark-blue;
  }
}
