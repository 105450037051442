@import '../../scss/variables.scss';

div.Consulta {
  position: relative;
  width: 513px;
  height: 147px;
  display: flex;
  border: 4px solid $dark-blue;
  border-radius: 35px 14px;
  cursor: pointer;
  margin-bottom: 34px;
  &:last-child {
    margin-bottom: 0;
  }
  * {
    cursor: pointer;
  }
  img {
    opacity: 0;
    transition: opacity 0.2s;
  }
  &.selected {
    img {
      opacity: 1;
    }
  }
  &.disabled {
    cursor: default;
    div.datetime {
      background-color: #818181;
    }
  }
  img#upper-left,
  img#lower-right {
    position: absolute;
  }
  img#upper-left {
    left: -30px;
    top: -30px;
  }
  img#lower-right {
    right: -30px;
    bottom: -30px;
    transform: scale(-1, -1);
  }
  div.datetime,
  div.dados {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  div.datetime {
    flex: 1;
    background-color: #4bafde;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 10px;
    align-items: center;
    span.title,
    span.content {
      color: white;
      font-style: italic;
      font-weight: 700;
      margin: 1px 0;
    }
    span.title {
      font-size: 9pt;
    }
    span.content {
      font-size: 14pt;
    }
  }
  div.dados {
    flex: 3;
    background-color: white;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 30px;
    padding-left: 36px;
    span.content {
      font-weight: 500;
      font-style: italic;
      font-size: 14pt;
      color: #818181;
      margin: 3px 0;
      span.title {
        font-weight: 800;
        color: $dark-blue;
      }
    }
  }
}
