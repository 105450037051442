$dark-blue: #3d68a0;
$light-blue: #0a71ad;
$cyan: #009de0;

$default-transition: 0.2s ease-in-out;

@mixin modal-small-text {
  font-style: italic;
  font-size: 16pt;
  font-weight: 500;
  color: $cyan;
  text-align: center;
}

@mixin page-container-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > h3,
  & > h5 {
    font-style: italic;
    font-weight: 800;
    text-align: center;
  }
  & > h3 {
    font-size: 25pt;
    color: $dark-blue;
    margin-bottom: 21px;
    &:not(:first-child) {
      margin-top: 55px;
    }
  }
  & > h5 {
    font-size: 18pt;
    color: $cyan;
    margin: 0 70px;
  }
  & > button {
    margin-top: 32px;
    width: 480px;
    height: 90px;
  }
}
