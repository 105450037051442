div.Inicio {
  background-image: url('../../assets/imgs/fundo\ inicio.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
  animation: fadeIn 0.5s linear;
  img#logo {
    position: absolute;
    top: 44px;
    left: 34px;
  }

  img.rosa--esquerda,
  img#rosa--direita {
    z-index: 20;
    position: absolute;
  }

  img.rosa--esquerda {
    bottom: 175px;
    right: 61px;
    &#complete {
      left: calc(100% - 61px);
    }
  }

  img#rosa--direita {
    bottom: 153px;
  }

  div#comecar-container {
    padding-top: 36px;
    position: absolute;
    bottom: 29px;
    right: 45px;
    height: 555px;
    width: 278px;
    background-color: rgba(17, 104, 163, 0.9);
    border-radius: 26px 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    * {
      cursor: pointer;
    }
    img#logo-branco {
      position: absolute;
      bottom: 27px;
      right: 27px;
    }
    h3,
    h6 {
      font-style: italic;
    }
    h3 {
      color: white;
      font-size: 22pt;
      font-weight: 800;
      margin: 22px 0;
    }
    h6 {
      margin: 0 50px;
      text-align: center;
      font-size: 18pt;
      font-weight: 500;
      color: #00b8ff;
    }
  }
}
