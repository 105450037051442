@import '../../scss/variables.scss';

div.ConsultaConfirmada {
  margin-top: 55px;
  width: 500px;
  max-height: 285px;
  min-height: 100px;
  background: white;
  border-radius: 35px 14px;
  border: 4px solid $light-blue;
  padding: 16px;
  div.consulta {
    width: 100%;
    display: flex;
    cursor: pointer;
    padding-bottom: 17px;
    border-bottom: 1px solid lightgray;
    div.datetime,
    div.dados {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    div.datetime {
      flex: 1;
      padding: 10px 20px;
      background-color: $light-blue;
      border-radius: 30px 10px;
      align-items: center;
      span.title,
      span.content {
        color: white;
        font-style: italic;
        font-weight: 700;
        margin: 1px 0;
      }
      span.title {
        font-size: 9pt;
      }
      span.content {
        font-size: 14pt;
      }
    }
    div.dados {
      flex: 3;
      background-color: white;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 30px;
      padding-left: 36px;
      span.content {
        font-weight: 500;
        font-style: italic;
        font-size: 14pt;
        color: #818181;
        margin: 3px 0;
        span.title {
          font-weight: 800;
          color: $dark-blue;
        }
      }
    }
  }
  div.atendimento {
    padding-top: 17px;
    font-style: italic;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    align-items: center;
    &:first-child {
      padding: 0;
    }
    & > span:first-child {
      color: $dark-blue;
      font-size: 17pt;
      margin-bottom: 8px;
      text-align: center;
    }
    & > div:first-of-type {
      color: white;
      background: $light-blue;
      padding: 6px 45px;
      border-radius: 20px 8px;
      font-size: 18pt;
    }
    &.pendencia {
      & > span:first-child {
        color: #edaf28;
      }
      & > div:first-of-type {
        background: #edaf28;
      }
    }
  }
}
