*.route-animate-exit {
  transition: opacity 0.5s;
  & > div {
    transition: opacity 0.5s;
  }
  &.route-animate-exit-active {
    opacity: 0;
    & > div {
      opacity: 0;
    }
  }
}
