@import '../../../scss/variables.scss';

div.modal-container {
  position: relative;
  left: -20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url('../../../assets/imgs/fundo\ modal.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 715px;
  width: 555px;
  border-radius: 110px 75px;
  overflow: hidden;
  padding: 45px 50px;
  padding-left: 50px + 50px;
  border-top-right-radius: 0;
  & > video#preview {
    border-radius: 50px;
    height: 250px;
  }
  & > img {
    max-height: 174px;
  }
  & > span {
    position: absolute;
    font-style: italic;
    font-weight: 800;
    font-size: 19pt;
    top: 12px;
    right: 3px;
    color: $cyan;
    cursor: pointer;
    &:hover {
      filter: brightness(2);
    }
  }
  & > h3,
  & > h6 {
    font-style: italic;
    text-align: center;
  }
  & > h3 {
    font-size: 26pt;
    font-weight: 800;
    color: $dark-blue;
    margin: 21px 0;
  }
  & > h6 {
    @include modal-small-text;
    margin: 30px 0;
  }
  & > div.buttons {
    position: absolute;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
    padding-left: 100px;
    padding-right: 45px;
    bottom: 45px;
    left: 0;
    button.modal-btn {
      width: 160px;
      height: 54px;
      h3 {
        font-size: 14pt;
        font-weight: 800;
        font-style: italic;
        margin: 0;
      }
    }
  }
  div.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}
